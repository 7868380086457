<template>
    <div>
        <h3>Encode/Decode Options</h3>
        <ul>
            <li><router-link to="base64" active-class="active-link">Base64 Encode</router-link></li>
            <li><router-link to="md5" active-class="active-link">Md5 Encode</router-link></li>
            <li><router-link to="url" active-class="active-link">URL Encode</router-link></li>
            <li><router-link to="html" active-class="active-link">HTML Encode</router-link></li>
        </ul>
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: 'EncodeDecode',
};
</script>

<style>
.active-link {
    font-weight: bold;
    color: blue;
    /* Change to your desired color */
    text-decoration: underline;
    /* For underlining the link */
}
</style>