<template>
  <Navbar />
  <router-view />
</template>
<script>
import Navbar from './views/navbar.vue';

export default {
  name: 'App',
  components: {
    Navbar,
  }
}
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  /* background-color: #f9f9f9; */
}

.main-content {
  display: flex;
  padding: 20px;
}

.editor,
.output {
  flex: 1;
  margin: 10px;
}
</style>
