<template>
    <header class="navbar">
        <router-link to="/json" class="logo">ImageX<span>.com</span></router-link>
        <nav>
            <router-link to="/json">Json</router-link>
            <router-link to="/encode">Encode</router-link>
            <router-link to="/image">ImageX</router-link>
            <router-link to="/aboutUs">About Us</router-link>
        </nav>
    </header>
</template>

<script>
export default {
    name: 'MainNavbar',
};
</script>

<style scoped>
.navbar {
    display: flex;
    height: 50px;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding: 0 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.logo {
    font-size: 24px;
    font-weight: bold;
    color: green;
    text-decoration: none;
}

.logo span {
    color: black;
}

nav a {
    margin-left: 20px;
    text-decoration: none;
    color: black;
    font-size: 16px;
}
</style>