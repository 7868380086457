<template>
    <div>
        <h3>ImageX Options</h3>
        <ul>
            <li><router-link to="resize" active-class="active-link">Image Resize</router-link></li>
            <li><router-link to="removeBg" active-class="active-link">Remove Background</router-link></li>
        </ul>
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: 'ImageX',
};
</script>
