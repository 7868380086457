import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'

import App from './App.vue'
import JsonFormatter from './components/JsonEncoder/JsonFormatter.vue'
import EncodeDecode from './components/EncodeDecode/EncodeDecode.vue'
import ImageX from './components/ImageX/Image.vue'
import AboutUs from './components/aboutUs.vue'

const routes = [
    { path: '/', component: JsonFormatter },
    { path: '/json', component: JsonFormatter },
    {
        path: '/encode', component: EncodeDecode,
        children: [
            { path: '', redirect: '/encode/base64' },
            { path: 'base64', component: () => import('./components/EncodeDecode/Base64Encoder.vue') },
            { path: 'md5', component: () => import('./components/EncodeDecode/Md5Encoder.vue') },
            { path: 'url', component: () => import('./components/EncodeDecode/UrlEncoder.vue') },
            { path: 'html', component: () => import('./components/EncodeDecode/HtmlEncoder.vue') },
        ],
    },
    {
        path: '/image', component: ImageX,
        children: [
            { path: '', redirect: '/image/resize' },
            { path: 'resize', component: () => import('./components/ImageX/ImageResize.vue') },
            { path: 'removeBg', component: () => import('./components/ImageX/ImageRemoveBg.vue') },
        ],
    },
    { path: '/aboutUs', component: AboutUs },
];
const router = createRouter(
    {
        history: createWebHistory(),
        routes
    }
);


createApp(App).use(router).mount('#app')
